<template lang="html">

  <section class="banner-head" :data-bg="dataTitle">
   <div class="aic container d-flex jcc">
      <h1 class="title-line -white">{{dataTitle}}</h1>
      <p class="description -white w-100" v-if="dataDescription">{{dataDescription}}</p>
   </div>
   
  </section>

</template>

<script lang="js">

  export default  {
    name: 'banner-head',
    props: ['dataTitle','dataDescription'],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .banner-head {

  }
</style>
