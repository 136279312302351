<template lang="html">

  <section class="breacrumbs">
    <div class="container" >

      <router-link :to="{name:'home'}">Inicio</router-link>
      <router-link :to="dataParentLink" v-if="dataParent && dataParentLink" >{{dataParent}}</router-link>
      <span class="item-breadcrumb" v-if="dataParent ">{{dataParent}}</span>
      <span v-if="dataTitle ">{{dataTitle}}</span>

    </div>

    <!-- En el menú "Noticias" quitamos el título de la noticia
        <div class="container" v-if="dataParent == 'Noticias' ">
      <router-link :to="{name:'home'}">Inicio</router-link>
      <router-link :to="dataParentLink" v-if="dataParent && dataParentLink" style="background-image:none" >{{dataParent}}</router-link>
    </div>
 -->

        <!--En el menú "Responsabilidad Social" quitamos el título de responsabilidad social que no lleva a ningún lado
        <div class="container" v-if="dataParent == 'Responsabilidad Social'">
           <router-link :to="{name:'home'}">Inicio</router-link>
      <router-link :to="dataParentLink" v-if="dataParent && dataParentLink" >{{dataParent}}</router-link>

      <span>{{dataTitle}}</span>
    </div>
-->

    
        <!--En el menú "Administración de personal y RRLL" quitamos el menú de migas
        <div class="container" v-if="dataParent == 'Mis gestiones'">

    </div>-->
  </section>

</template>

<script lang="js">

  export default {
    name: 'breacrumbs',
    props: ['dataParent', 'dataTitle', 'dataParentLink'],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .breacrumbs {}
</style>